import React, { useState } from 'react';
import sessamiPreview from '../assets/images/previews/sessamiPreview.png';
import nakedHarePreview from '../assets/images/previews/nakedHarePreview.png';
import enRootPreview from '../assets/images/previews/enRootPreview.png';

function ProjectShowcase() {
  const [currentProject, setCurrentProject] = useState(0);
  
  const projects = [
    {
      title: "How We Built Sessami.co",
      description: "We developed Sessami's complete digital ecosystem, crafting both their web platform and native mobile apps. This comprehensive event discovery and booking solution showcases our ability to deliver seamless experiences across all devices.",
      image: sessamiPreview,
      platforms: {
        web: "https://sessami.co",
        ios: "https://apps.apple.com/gb/app/sessami/id1578022522",
        android: "https://play.google.com/store/apps/details?id=com.sessami"
      },
      features: [
        {
          title: "Cross-Platform Development",
          description: "Built and launched responsive web platform alongside native iOS and Android apps for complete market coverage"
        },
        {
          title: "Unified Experience",
          description: "Seamlessly synchronized booking system and user accounts across web and mobile platforms"
        },
        {
          title: "Performance Optimised",
          description: "Lightning-fast load times and real-time updates across all platforms using React and React Native"
        }
      ]
    },
    {
      title: "Transforming NakedHare.co.uk",
      description: "We engineered NakedHare's complete digital presence, delivering both a high-performance e-commerce website and feature-rich mobile apps. Our solution revolutionized their customer experience across all platforms.",
      image: nakedHarePreview,
      platforms: {
        web: "https://nakedharegroup.com",
        ios: "https://apps.apple.com/gb/app/naked-hare-group/id1574038371",
        android: "https://play.google.com/store/apps/details?id=com.nakedhareapp&hl=en_GB"
      },
      features: [
        {
          title: "Unified Commerce Platform",
          description: "Developed integrated web and mobile shopping experiences with synchronized cart and inventory"
        },
        {
          title: "Native Mobile Apps",
          description: "Built dedicated iOS and Android apps with platform-specific optimisations for peak performance"
        },
        {
          title: "Seamless Integration",
          description: "Connected web and mobile platforms with custom CMS for unified content and inventory management"
        }
      ]
    },
    {
      title: "Elevating EnrootLDN.co.uk",
      description: "We created EnRoot's complete digital ecosystem from the ground up, developing both their sustainable-focused website and user-friendly mobile apps. Our solution brings their eco-conscious vision to life across all platforms.",
      image: enRootPreview,
      platforms: {
        web: "https://enrootldn.co.uk",
        ios: "https://apps.apple.com/us/app/en-root/id6474153758",
        android: "https://play.google.com/store/apps/details?id=com.enrootapp&hl=en_GB"
      },
      features: [
        {
          title: "Multi-Platform Excellence",
          description: "Developed responsive website and native mobile apps with consistent brand experience"
        },
        {
          title: "Sustainable Architecture",
          description: "Built efficient, eco-conscious platforms optimised for minimal environmental impact"
        },
        {
          title: "Cross-Platform Integration",
          description: "Implemented seamless data synchronization between web and mobile platforms"
        }
      ]
    }
  ];

  const nextProject = () => {
    setCurrentProject((prev) => (prev + 1) % projects.length);
  };

  const prevProject = () => {
    setCurrentProject((prev) => (prev - 1 + projects.length) % projects.length);
  };

  const PlatformIcons = ({ platforms }) => {
    return (
      <div className="flex justify-center space-x-6 mt-4">
        {platforms.web && (
          <a
            href={platforms.web}
            target="_blank"
            rel="noopener noreferrer"
            className="text-affirm-purple hover:text-affirm-blue-light transition-colors"
            title="Visit Website"
          >
            <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"/>
            </svg>
          </a>
        )}
        {platforms.ios && (
          <a
            href={platforms.ios}
            target="_blank"
            rel="noopener noreferrer"
            className="text-affirm-purple hover:text-affirm-blue-light transition-colors"
            title="Download on iOS"
          >
            <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 384 512">
              <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"/>
            </svg>
          </a>
        )}
        {platforms.android && (
          <a
            href={platforms.android}
            target="_blank"
            rel="noopener noreferrer"
            className="text-affirm-purple hover:text-affirm-blue-light transition-colors"
            title="Download on Android"
          >
            <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
              <path d="M6 18c0 .55.45 1 1 1h1v3.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5V19h2v3.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5V19h1c.55 0 1-.45 1-1V8H6v10zM3.5 8C2.67 8 2 8.67 2 9.5v7c0 .83.67 1.5 1.5 1.5S5 17.33 5 16.5v-7C5 8.67 4.33 8 3.5 8zm17 0c-.83 0-1.5.67-1.5 1.5v7c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-7c0-.83-.67-1.5-1.5-1.5zm-4.97-5.84l1.3-1.3c.2-.2.2-.51 0-.71-.2-.2-.51-.2-.71 0l-1.48 1.48C13.85 1.23 12.95 1 12 1c-.96 0-1.86.23-2.66.63L7.85.15c-.2-.2-.51-.2-.71 0-.2.2-.2.51 0 .71l1.31 1.31C6.97 3.26 6 5.01 6 7h12c0-1.99-.97-3.75-2.47-4.84zM10 5H9V4h1v1zm5 0h-1V4h1v1z"/>
            </svg>
          </a>
        )}
      </div>
    );
  };

  return (
    <div className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-nexa font-bold text-affirm-purple mb-4">
            Our Work
          </h2>
          <div className="w-32 h-1 bg-gradient-affirm mx-auto"></div>
        </div>

        <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 p-8">
            <div key={currentProject} className="flex flex-col justify-center animate-fadeIn">
              <h3 className="text-2xl font-nexa font-bold text-affirm-purple mb-4">
                {projects[currentProject].title}
              </h3>
              <p className="text-gray-600 mb-6">
                {projects[currentProject].description}
              </p>
              <div className="space-y-4">
                {projects[currentProject].features.map((feature, index) => (
                  <div key={index} className="flex items-start">
                    <div className="flex-shrink-0">
                      <div className="w-8 h-8 bg-gradient-affirm rounded-full flex items-center justify-center">
                        <span className="text-white text-lg">✓</span>
                      </div>
                    </div>
                    <div className="ml-4">
                      <h4 className="text-lg font-nexa font-bold text-gray-900">{feature.title}</h4>
                      <p className="text-gray-600">{feature.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div key={`image-${currentProject}`} className="flex flex-col items-center">
              <div className="w-full bg-gray-50 rounded-lg overflow-hidden shadow-lg">
                <img 
                  src={projects[currentProject].image} 
                  alt={projects[currentProject].title}
                  className="w-full h-auto object-contain transform transition-transform duration-500 hover:scale-105"
                />
              </div>
              <PlatformIcons platforms={projects[currentProject].platforms} />
            </div>
          </div>
        </div>

        {/* Navigation Controls */}
        <div className="mt-8 flex flex-col items-center">
          {/* Navigation Buttons */}
          <div className="flex justify-center space-x-4 mb-4">
            <button
              onClick={prevProject}
              className="w-12 h-12 rounded-full bg-affirm-purple text-white flex items-center justify-center hover:bg-opacity-90 transition-all"
            >
              ←
            </button>
            <button
              onClick={nextProject}
              className="w-12 h-12 rounded-full bg-affirm-purple text-white flex items-center justify-center hover:bg-opacity-90 transition-all"
            >
              →
            </button>
          </div>

          {/* Dots Indicator */}
          <div className="flex justify-center space-x-2">
            {projects.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentProject(index)}
                className={`w-2 h-2 rounded-full transition-all ${
                  currentProject === index ? 'bg-affirm-purple w-4' : 'bg-gray-300'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectShowcase;
