import React from 'react';

const UIUXDesign = () => {
  return (
    <div className="container mx-auto px-4 py-8 sm:py-12">
      <h1 className="text-3xl sm:text-4xl font-nexa font-bold text-gray-900 mb-6 sm:mb-8">UI/UX Design</h1>
          <div className="mb-6 rounded-lg overflow-hidden shadow-lg">
            <img 
              src="https://placehold.co/600x400/2563eb/ffffff?text=User+Centered+Design" 
              alt="User-Centered Design Solutions" 
              className="w-full h-auto"
            />
          </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-12">
        <div>
          <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-3 sm:mb-4">User-Centered Design Solutions</h2>
          <p className="text-sm sm:text-base text-gray-600 mb-6">
            We create intuitive, engaging digital experiences that put users first. 
            Our design process combines aesthetics with functionality to deliver 
            interfaces that are both beautiful and easy to use.
          </p>
          
          <h3 className="text-lg sm:text-xl font-nexa font-bold text-gray-800 mb-2 sm:mb-3">Our Design Services:</h3>
          <ul className="list-disc list-inside text-sm sm:text-base text-gray-600 space-y-1.5 sm:space-y-2 mb-6">
            <li>User Experience (UX) Design</li>
            <li>User Interface (UI) Design</li>
            <li>User Research & Testing</li>
            <li>Wireframing & Prototyping</li>
            <li>Interaction Design</li>
            <li>Design Systems</li>
          </ul>
        </div>
        
        <div>
          {/* <div className="mb-6 rounded-lg overflow-hidden shadow-lg">
            <img 
              src="https://placehold.co/600x400/2563eb/ffffff?text=Design+Expertise" 
              alt="Design Expertise" 
              className="w-full h-auto"
            />
          </div> */}
          <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-3 sm:mb-4">Design Expertise</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="bg-white p-4 rounded-lg shadow">
              <h4 className="font-nexa font-bold text-gray-800 mb-2 text-base sm:text-lg">UX Design</h4>
              <ul className="text-sm sm:text-base text-gray-600 space-y-1">
                <li>User Research</li>
                <li>Information Architecture</li>
                <li>User Flows</li>
                <li>Usability Testing</li>
              </ul>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
              <h4 className="font-nexa font-bold text-gray-800 mb-2 text-base sm:text-lg">UI Design</h4>
              <ul className="text-sm sm:text-base text-gray-600 space-y-1">
                <li>Visual Design</li>
                <li>Interface Elements</li>
                <li>Responsive Design</li>
                <li>Animation</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mt-8 sm:mt-12">
        <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-4 sm:mb-6">Our Design Process</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 sm:gap-8">
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src="https://placehold.co/400x300/2563eb/ffffff?text=Research" 
              alt="Research" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">1. Research</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Understanding user needs, behaviors, and pain points through research 
                and analysis.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src="https://placehold.co/400x300/2563eb/ffffff?text=Design" 
              alt="Design" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">2. Design</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Creating wireframes, prototypes, and high-fidelity designs based on 
                research insights.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src="https://placehold.co/400x300/2563eb/ffffff?text=Test" 
              alt="Test" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">3. Test</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Conducting user testing and gathering feedback to validate design 
                decisions.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src="https://placehold.co/400x300/2563eb/ffffff?text=Iterate" 
              alt="Iterate" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">4. Iterate</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Refining designs based on user feedback and testing results for 
                optimal experience.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mt-8 sm:mt-12">
        <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-4 sm:mb-6">Design Tools & Technologies</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src="https://placehold.co/400x300/2563eb/ffffff?text=Design+Tools" 
              alt="Design Tools" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">Design Tools</h3>
              <ul className="text-sm sm:text-base text-gray-600 space-y-1.5 sm:space-y-2">
                <li>Figma</li>
                <li>Adobe XD</li>
                <li>Sketch</li>
                <li>InVision</li>
              </ul>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src="https://placehold.co/400x300/2563eb/ffffff?text=Prototyping" 
              alt="Prototyping" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">Prototyping</h3>
              <ul className="text-sm sm:text-base text-gray-600 space-y-1.5 sm:space-y-2">
                <li>Principle</li>
                <li>Framer</li>
                <li>ProtoPie</li>
                <li>Flinto</li>
              </ul>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src="https://placehold.co/400x300/2563eb/ffffff?text=Testing+Tools" 
              alt="Testing Tools" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">Testing Tools</h3>
              <ul className="text-sm sm:text-base text-gray-600 space-y-1.5 sm:space-y-2">
                <li>UserTesting</li>
                <li>Hotjar</li>
                <li>Maze</li>
                <li>Optimal Workshop</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UIUXDesign;
