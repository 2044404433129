import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyBLGFxl1gFgDNqy5UjtA73TCkySeuYcCL8",
  authDomain: "affirm-a576c.firebaseapp.com",
  projectId: "affirm-a576c",
  storageBucket: "affirm-a576c.firebasestorage.app",
  messagingSenderId: "206487483340",
  appId: "1:206487483340:web:8169236e3e8dd5270b2369",
  measurementId: "G-VLLJ3CVYDS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;
