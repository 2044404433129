import React from 'react';
import { Link } from 'react-router-dom';
import webImage from '../assets/images/heroImages/AAHero1.jpg';
import mobileDev from '../assets/images/serviceImages/mobiledev.jpg';
import digitalMarketing from '../assets/images/serviceImages/digitalmarketing.jpg';

const services = [
  {
    title: 'Web Development',
    description: 'Custom web solutions built with modern technologies to help your business grow and scale.',
    icon: '🌐',
    path: '/services/web-development',
    image: webImage
  },
  {
    title: 'Mobile App Development',
    description: 'Native iOS and Android applications built with React Native for seamless cross-platform experiences.',
    icon: '📱',
    path: '/services/mobile-app-development',
    image: mobileDev
  },
  {
    title: 'Digital Marketing',
    description: 'Strategic digital marketing campaigns to boost your online presence and drive conversions.',
    icon: '📢',
    path: '/services/digital-marketing',
    image: digitalMarketing
  }
];

const ServicesList = ({ variant = 'detailed' }) => {
  if (variant === 'simple') {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <Link to={service.path} key={index} className="group">
            <div className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-all overflow-hidden">
              <div className="h-48 w-full overflow-hidden">
                <img 
                  src={service.image} 
                  alt={service.title}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-nexa font-bold text-affirm-purple mb-3">{service.title}</h3>
                <p className="text-gray-600">{service.description}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
      {services.map((service, index) => (
        <Link 
          to={service.path}
          key={index}
          className="rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 bg-white overflow-hidden"
        >
          <div className="h-48 w-full overflow-hidden">
            <img 
              src={service.image} 
              alt={service.title}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="p-5 sm:p-6">
            <div className="text-3xl sm:text-4xl mb-3 sm:mb-4">{service.icon}</div>
            <h3 className="text-lg sm:text-xl font-nexa font-bold text-affirm-purple mb-2 sm:mb-3">
              {service.title}
            </h3>
            <p className="text-gray-600 text-sm sm:text-base mb-3 sm:mb-4">
              {service.description}
            </p>
            <span className="text-affirm-blue-light font-nexa text-sm sm:text-base hover:underline">
              Learn more →
            </span>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default ServicesList;
