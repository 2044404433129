import React from 'react';
import { Link } from 'react-router-dom';
import coffee from '../assets/images/serviceImages/coffee.jpg';
import barbers from '../assets/images/serviceImages/barbers.jpg';
import restaurant from '../assets/images/serviceImages/restaurant.jpg';


const industries = [
  {
    title: 'Coffee Shops',
    path: '/industries/coffee-shops',
    description: 'Digital solutions tailored for modern coffee shops and cafes.',
    image: coffee
  },
  {
    title: 'Hair & Beauty',
    path: '/industries/hair-beauty',
    description: 'Elevate your salon\'s digital presence and client experience.',
    image: barbers
  },
  {
    title: 'Restaurants',
    path: '/industries/restaurants',
    description: 'Comprehensive digital solutions for the restaurant industry.',
    image: restaurant
  },
];

const IndustriesList = ({ variant = 'detailed' }) => {
  if (variant === 'simple') {
    return (
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {industries.map((industry) => (
          <Link to={industry.path} key={industry.path} className="group">
            <div className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-all overflow-hidden">
              <div className="w-full aspect-[4/3] overflow-hidden">
                <img 
                  src={industry.image} 
                  alt={industry.title}
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-nexa font-bold text-affirm-purple mb-3">{industry.title}</h3>
                <p className="text-gray-600">{industry.description}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-8">
      {industries.map((industry) => (
        <Link
          key={industry.path}
          to={industry.path}
          className="block group"
        >
          <div className="bg-gray-50 rounded-lg transition-all duration-300 hover:shadow-lg hover:bg-affirm-blue-light hover:bg-opacity-10 overflow-hidden">
            <div className="w-full aspect-[4/3] overflow-hidden">
              <img 
                src={industry.image} 
                alt={industry.title}
                className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
              />
            </div>
            <div className="p-4 sm:p-6">
              <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-900 mb-2 sm:mb-4 group-hover:text-affirm-blue-dark">
                {industry.title}
              </h2>
              <p className="text-sm sm:text-base text-gray-700">
                {industry.description}
              </p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default IndustriesList;
