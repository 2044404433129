import React from 'react';

const BrandStrategy = () => {
  return (
    <div className="container mx-auto px-4 py-8 sm:py-12">
      <h1 className="text-3xl sm:text-4xl font-nexa font-bold text-gray-900 mb-6 sm:mb-8">Brand Strategy</h1>
          <div className="mb-6 rounded-lg overflow-hidden shadow-lg">
            <img 
              src="https://placehold.co/600x400/2563eb/ffffff?text=Brand+Development" 
              alt="Strategic Brand Development" 
              className="w-full h-auto"
            />
          </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-12">
        <div>
          <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-3 sm:mb-4">Strategic Brand Development</h2>
          <p className="text-sm sm:text-base text-gray-600 mb-6">
            We help businesses build strong, memorable brands that resonate with their 
            target audience. Our strategic approach combines market research, competitor 
            analysis, and consumer insights to create authentic brand experiences.
          </p>
          
          <h3 className="text-lg sm:text-xl font-nexa font-bold text-gray-800 mb-2 sm:mb-3">Our Brand Services:</h3>
          <ul className="list-disc list-inside text-sm sm:text-base text-gray-600 space-y-1.5 sm:space-y-2 mb-6">
            <li>Brand Identity Development</li>
            <li>Brand Positioning</li>
            <li>Brand Voice & Messaging</li>
            <li>Visual Identity Systems</li>
            <li>Brand Guidelines</li>
            <li>Brand Architecture</li>
          </ul>
        </div>
        
        <div>
          {/* <div className="mb-6 rounded-lg overflow-hidden shadow-lg">
            <img 
              src="https://placehold.co/600x400/2563eb/ffffff?text=Brand+Elements" 
              alt="Brand Elements" 
              className="w-full h-auto"
            />
          </div> */}
          <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-3 sm:mb-4">Brand Elements</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="bg-white p-4 rounded-lg shadow">
              <h4 className="font-nexa font-bold text-gray-800 mb-2 text-base sm:text-lg">Visual Identity</h4>
              <ul className="text-sm sm:text-base text-gray-600 space-y-1">
                <li>Logo Design</li>
                <li>Color Palette</li>
                <li>Typography</li>
                <li>Image Style</li>
              </ul>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
              <h4 className="font-nexa font-bold text-gray-800 mb-2 text-base sm:text-lg">Brand Voice</h4>
              <ul className="text-sm sm:text-base text-gray-600 space-y-1">
                <li>Tone & Personality</li>
                <li>Key Messages</li>
                <li>Brand Story</li>
                <li>Communication Style</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mt-8 sm:mt-12">
        <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-4 sm:mb-6">Our Brand Strategy Process</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-6 sm:gap-8">
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src="https://placehold.co/400x300/2563eb/ffffff?text=Discovery" 
              alt="Discovery" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">1. Discovery</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Understanding your business, values, goals, and target audience through 
                in-depth research.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src="https://placehold.co/400x300/2563eb/ffffff?text=Analysis" 
              alt="Analysis" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">2. Analysis</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Market research, competitor analysis, and identifying opportunities for 
                brand differentiation.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src="https://placehold.co/400x300/2563eb/ffffff?text=Strategy" 
              alt="Strategy" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">3. Strategy</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Developing positioning, messaging, and visual direction aligned with 
                business objectives.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src="https://placehold.co/400x300/2563eb/ffffff?text=Creation" 
              alt="Creation" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">4. Creation</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Designing and developing brand elements and guidelines that bring your 
                brand to life.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src="https://placehold.co/400x300/2563eb/ffffff?text=Implementation" 
              alt="Implementation" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">5. Implementation</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Rolling out your brand across all touchpoints and training your team 
                on brand guidelines.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mt-8 sm:mt-12">
        <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-4 sm:mb-6">Brand Monitoring & Evolution</h2>
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <img 
            src="https://placehold.co/800x400/2563eb/ffffff?text=Brand+Monitoring" 
            alt="Brand Monitoring and Evolution" 
            className="w-full h-64 object-cover"
          />
          <div className="p-4 sm:p-6">
            <p className="text-sm sm:text-base text-gray-600">
              We help maintain brand consistency while allowing for strategic evolution 
              as your business grows. Our ongoing brand monitoring ensures your brand 
              remains relevant and effective in an ever-changing market landscape.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandStrategy;
