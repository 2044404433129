import React from 'react';
import restaurant from '../../assets/images/serviceImages/restaurant.jpg';

const Restaurants = () => {
  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-16">
        <div className="mb-8 rounded-lg overflow-hidden shadow-lg">
          <img 
            src={restaurant}
            alt="Restaurant Solutions" 
          className="w-full h-96 object-cover"
          />
        </div>

        <h1 className="text-3xl sm:text-4xl font-nexa font-bold text-gray-900 mb-4 sm:mb-8">
          Restaurant Solutions
        </h1>
        
        <div className="prose max-w-none">
          <p className="text-base sm:text-lg text-gray-700 mb-6">
            We empower restaurants with digital solutions that enhance customer experience and streamline operations. Our expertise helps restaurants thrive in the digital age.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-8 my-8 sm:my-12">
            <div className="bg-gray-50 rounded-lg overflow-hidden">
              <img 
                src="https://placehold.co/600x300/2563eb/ffffff?text=Digital+Experience" 
                alt="Digital Experience" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4 sm:p-6">
                <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-900 mb-3 sm:mb-4">
                  Digital Experience
                </h2>
                <ul className="space-y-2 sm:space-y-3 text-sm sm:text-base">
                  <li>Interactive menu systems</li>
                  <li>Online ordering platforms</li>
                  <li>Reservation system integration</li>
                  <li>Virtual restaurant tours</li>
                </ul>
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg overflow-hidden">
              <img 
                src="https://placehold.co/600x300/2563eb/ffffff?text=Marketing+%26+Growth" 
                alt="Marketing and Growth" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4 sm:p-6">
                <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-900 mb-3 sm:mb-4">
                  Marketing & Growth
                </h2>
                <ul className="space-y-2 sm:space-y-3 text-sm sm:text-base">
                  <li>Food photography and presentation</li>
                  <li>Social media management</li>
                  <li>Email marketing campaigns</li>
                  <li>Customer loyalty programs</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 my-8 sm:my-12">
            <div className="bg-gray-50 rounded-lg overflow-hidden">
              <img 
                src="https://placehold.co/400x300/2563eb/ffffff?text=Online+Ordering" 
                alt="Online Ordering" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="font-nexa font-bold text-lg mb-2">Online Ordering</h3>
                <p className="text-sm text-gray-700">Seamless ordering system for takeout and delivery</p>
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg overflow-hidden">
              <img 
                src="https://placehold.co/400x300/2563eb/ffffff?text=Digital+Menu" 
                alt="Digital Menu" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="font-nexa font-bold text-lg mb-2">Digital Menu</h3>
                <p className="text-sm text-gray-700">Interactive menus with beautiful food photography</p>
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg overflow-hidden">
              <img 
                src="https://placehold.co/400x300/2563eb/ffffff?text=Reservations" 
                alt="Reservations" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="font-nexa font-bold text-lg mb-2">Reservations</h3>
                <p className="text-sm text-gray-700">Easy-to-use booking system for your customers</p>
              </div>
            </div>
          </div>

          <div className="bg-affirm-blue-light bg-opacity-10 rounded-lg overflow-hidden my-8 sm:my-12">
            <img 
              src="https://placehold.co/1200x400/2563eb/ffffff?text=Why+Choose+Us" 
              alt="Why Choose Us" 
              className="w-full h-64 object-cover"
            />
            <div className="p-4 sm:p-8">
              <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-900 mb-3 sm:mb-4">
                Why Choose Us?
              </h2>
              <p className="text-sm sm:text-base text-gray-700 mb-3 sm:mb-4">
                We understand the unique challenges restaurants face in today's digital world. Our solutions are designed to enhance your restaurant's online presence while maintaining the personal touch that makes your establishment special.
              </p>
              <p className="text-sm sm:text-base text-gray-700">
                From fine dining to casual eateries, we create custom digital solutions that complement your restaurant's atmosphere and improve operational efficiency.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Restaurants;
