import React from 'react';
import digitalmarketing from '../../assets/images/serviceImages/digitalmarketing.jpg';
import strategy from '../../assets/images/serviceImages/strategy.jpg';
import execute from '../../assets/images/serviceImages/execute.jpg';
import research from '../../assets/images/serviceImages/research.jpg';
import optimise from '../../assets/images/serviceImages/optimise.jpg';

const DigitalMarketing = () => {
  return (
    <div className="container mx-auto px-4 py-8 sm:py-12">
      <h1 className="text-3xl sm:text-4xl font-nexa font-bold text-gray-900 mb-6 sm:mb-8">Digital Marketing</h1>
         
      <div className="mb-6 rounded-lg overflow-hidden shadow-lg">
            <img 
          src={digitalmarketing} 
              alt="Digital Marketing Solutions" 
              className="w-full h-auto"
            />
          </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-12">
        <div>
          <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-3 sm:mb-4">Comprehensive Digital Marketing Solutions</h2>
          <p className="text-sm sm:text-base text-gray-600 mb-6">
            We develop data-driven digital marketing strategies that help your business 
            reach its target audience, increase engagement, and drive conversions. Our 
            holistic approach ensures all channels work together seamlessly.
          </p>
          
          <h3 className="text-lg sm:text-xl font-nexa font-bold text-gray-800 mb-2 sm:mb-3">Our Services Include:</h3>
          <ul className="list-disc list-inside text-sm sm:text-base text-gray-600 space-y-1.5 sm:space-y-2 mb-6">
            <li>Search Engine Optimisation (SEO)</li>
            <li>Pay-Per-Click (PPC) Advertising</li>
            <li>Social Media Marketing</li>
            <li>Content Marketing</li>
            <li>Email Marketing Campaigns</li>
            <li>Analytics and Reporting</li>
          </ul>
        </div>
        
        <div>
          {/* <div className="mb-6 rounded-lg overflow-hidden shadow-lg">
            <img 
              src="https://placehold.co/600x400/2563eb/ffffff?text=Marketing+Channels" 
              alt="Marketing Channels" 
              className="w-full h-auto"
            />
          </div> */}
          <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-3 sm:mb-4">Marketing Channels</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="bg-white p-4 rounded-lg shadow">
              <h4 className="font-nexa font-bold text-gray-800 mb-2 text-base sm:text-lg">Organic</h4>
              <ul className="text-sm sm:text-base text-gray-600 space-y-1">
                <li>SEO Optimisation</li>
                <li>Content Strategy</li>
                <li>Social Media</li>
                <li>Blog Management</li>
              </ul>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
              <h4 className="font-nexa font-bold text-gray-800 mb-2 text-base sm:text-lg">Paid</h4>
              <ul className="text-sm sm:text-base text-gray-600 space-y-1">
                <li>Google Ads</li>
                <li>Social Media Ads</li>
                <li>Display Advertising</li>
                <li>Retargeting</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mt-8 sm:mt-12">
        <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-4 sm:mb-6">Our Marketing Approach</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 sm:gap-8">
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src={research} 
              alt="Research" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">1. Research</h3>
              <p className="text-sm sm:text-base text-gray-600">
                We analyze your market, competitors, and target audience to develop 
                effective strategies.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src={strategy} 
              alt="Strategy" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">2. Strategy</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Creating comprehensive marketing plans aligned with your business goals 
                and budget.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src={execute}
              alt="Execute" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">3. Execute</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Implementing campaigns across chosen channels with consistent messaging 
                and branding.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src={optimise} 
              alt="Optimise" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">4. Optimise</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Continuous monitoring and optimisation based on performance data and analytics.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* <div className="mt-8 sm:mt-12">
        <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-4 sm:mb-6">Analytics & Reporting</h2>
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <img 
            src="https://placehold.co/800x400/2563eb/ffffff?text=Analytics+%26+Reporting" 
            alt="Analytics and Reporting" 
            className="w-full h-64 object-cover"
          />
          <div className="p-4 sm:p-6">
            <p className="text-sm sm:text-base text-gray-600">
              We provide detailed monthly reports showing key performance indicators, 
              campaign results, and ROI metrics. Our transparent reporting helps you 
              understand the impact of your marketing investments and guides future 
              strategy decisions.
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default DigitalMarketing;
