import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ show: false, success: false, message: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus({ show: false, success: false, message: '' });

    try {
      const result = await emailjs.sendForm(
        'tgf-NR',
        'template_l7kglso',
        form.current,
        '5d_c_KwO1AW39E3iL'
      );

      if (result.text === 'OK') {
        setStatus({
          show: true,
          success: true,
          message: 'Message sent successfully! We\'ll get back to you soon.'
        });
        form.current.reset();
      }
    } catch (error) {
      setStatus({
        show: true,
        success: false,
        message: 'Failed to send message. Please try again.'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16">
        <div className="text-center mb-12 sm:mb-16">
          <h1 className="text-3xl sm:text-4xl font-nexa font-bold text-affirm-purple mb-4">
            Get in Touch
          </h1>
          <div className="w-24 sm:w-32 h-1 bg-gradient-affirm mx-auto"></div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12">
          <div className="space-y-6 sm:space-y-8">
            <div>
              <h2 className="text-xl sm:text-2xl font-nexa font-bold text-affirm-purple mb-3 sm:mb-4">
                Let's Create Something Amazing Together
              </h2>
              <p className="text-gray-600 text-sm sm:text-base">
                Ready to transform your digital presence? We're here to help bring your vision to life.
              </p>
            </div>

            <div className="space-y-6">
              <div className="flex items-center">
                <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-gradient-affirm flex items-center justify-center text-white">
                  <span className="text-base sm:text-xl">📍</span>
                </div>
                <div className="ml-4">
                  <h3 className="font-nexa font-bold text-affirm-purple text-sm sm:text-base">Location</h3>
                  <p className="text-gray-600 text-sm sm:text-base">Surrey, UK</p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-gradient-affirm flex items-center justify-center text-white">
                  <span className="text-base sm:text-xl">✉️</span>
                </div>
                <div className="ml-4">
                  <h3 className="font-nexa font-bold text-affirm-purple text-sm sm:text-base">Email</h3>
                  <p className="text-gray-600 text-sm sm:text-base">hello@affirm.agency</p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6 sm:p-8">
            <form ref={form} onSubmit={handleSubmit} className="space-y-5 sm:space-y-6">
              {status.show && (
                <div className={`p-4 rounded-lg ${status.success ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                  {status.message}
                </div>
              )}
              <div>
                <label htmlFor="user_name" className="block text-sm font-nexa font-bold text-affirm-purple mb-2">
                  Name
                </label>
                <input
                  type="text"
                  id="user_name"
                  name="user_name"
                  required
                  className="w-full px-3 sm:px-4 py-2 text-sm sm:text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-affirm-blue-light"
                  placeholder="Your name"
                />
              </div>
              <div>
                <label htmlFor="user_email" className="block text-sm font-nexa font-bold text-affirm-purple mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="user_email"
                  name="user_email"
                  required
                  className="w-full px-3 sm:px-4 py-2 text-sm sm:text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-affirm-blue-light"
                  placeholder="your@email.com"
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-nexa font-bold text-affirm-purple mb-2">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  required
                  className="w-full px-3 sm:px-4 py-2 text-sm sm:text-base border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-affirm-blue-light"
                  placeholder="Tell us about your project"
                ></textarea>
              </div>
              <button
                type="submit"
                disabled={loading}
                className="w-full py-2.5 sm:py-3 px-6 text-sm sm:text-base text-white font-nexa font-bold rounded-lg bg-gradient-affirm hover:opacity-90 transition-opacity disabled:opacity-50"
              >
                {loading ? 'Sending...' : 'Send Message'}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
