import React from 'react';
import { Link } from 'react-router-dom';
import ProjectShowcase from '../components/ProjectShowcase';
import HeroBackground from '../components/HeroBackground';
import ServicesList from '../components/ServicesList';
import IndustriesList from '../components/IndustriesList';

function Home() {
  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <div className="relative text-white">
        <HeroBackground />
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 md:py-24">
          <div className="text-center">
            <h1 className="text-4xl sm:text-5xl md:text-6xl font-nexa font-bold mb-4 md:mb-6 leading-tight">
              Transform Your Digital Presence
            </h1>
            <p className="text-lg sm:text-xl md:text-2xl mb-8 md:mb-12 opacity-90 max-w-3xl mx-auto">
              We build exceptional web apps, mobile apps, and social media strategies
            </p>
            <div className="space-x-4">
              <Link
                to="/contact"
                className="inline-block bg-white text-affirm-purple font-nexa font-bold px-6 sm:px-8 py-3 sm:py-4 rounded-lg hover:bg-opacity-90 transition-all transform hover:scale-105 text-sm sm:text-base"
              >
                Start Your Project
              </Link>
              <Link
                to="/services"
                className="inline-block bg-transparent border-2 border-white text-white font-nexa font-bold px-6 sm:px-8 py-3 sm:py-4 rounded-lg hover:bg-white hover:text-affirm-purple transition-all transform hover:scale-105 text-sm sm:text-base"
              >
                Our Services
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Services Section */}
      <div className="py-16 md:py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12 md:mb-16">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-nexa font-bold text-affirm-purple mb-4">
              Our Services
            </h2>
            <div className="w-24 md:w-32 h-1 bg-gradient-affirm mx-auto"></div>
          </div>
          <ServicesList variant="simple" />
        </div>
      </div>

      {/* Features Section */}
      <div className="py-16 md:py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12 md:mb-16">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-nexa font-bold text-affirm-purple mb-4">
              Why Choose Affirm?
            </h2>
            <div className="w-24 md:w-32 h-1 bg-gradient-affirm mx-auto"></div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12">
            <div className="text-center px-4">
              <div className="w-14 h-14 md:w-16 md:h-16 bg-gradient-affirm rounded-full flex items-center justify-center mx-auto mb-4 md:mb-6">
                <span className="text-xl md:text-2xl">💡</span>
              </div>
              <h3 className="text-lg md:text-xl font-nexa font-bold text-affirm-purple mb-3 md:mb-4">
                Innovative Solutions
              </h3>
              <p className="text-gray-600 text-sm md:text-base">
                Cutting-edge technology meets creative design to deliver exceptional results
              </p>
            </div>

            <div className="text-center px-4">
              <div className="w-14 h-14 md:w-16 md:h-16 bg-gradient-affirm rounded-full flex items-center justify-center mx-auto mb-4 md:mb-6">
                <span className="text-xl md:text-2xl">⚡</span>
              </div>
              <h3 className="text-lg md:text-xl font-nexa font-bold text-affirm-purple mb-3 md:mb-4">
                Fast Delivery
              </h3>
              <p className="text-gray-600 text-sm md:text-base">
                Efficient development process ensuring quick turnaround without compromising quality
              </p>
            </div>

            <div className="text-center px-4">
              <div className="w-14 h-14 md:w-16 md:h-16 bg-gradient-affirm rounded-full flex items-center justify-center mx-auto mb-4 md:mb-6">
                <span className="text-xl md:text-2xl">🤝</span>
              </div>
              <h3 className="text-lg md:text-xl font-nexa font-bold text-affirm-purple mb-3 md:mb-4">
                Expert Support
              </h3>
              <p className="text-gray-600 text-sm md:text-base">
                Dedicated team of professionals providing ongoing support and maintenance
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Industries Section */}
      {/* <div className="py-16 md:py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12 md:mb-16">
            <h2 className="text-2xl sm:text-3xl md:text-4xl font-nexa font-bold text-affirm-purple mb-4">
              Industries We Serve
            </h2>
            <div className="w-24 md:w-32 h-1 bg-gradient-affirm mx-auto"></div>
          </div>
          <IndustriesList variant="simple" />
        </div>
      </div> */}

      {/* Project Showcase Section */}
      <ProjectShowcase />

      {/* CTA Section */}
      <div className="bg-affirm-purple text-white py-16 md:py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-nexa font-bold mb-4 md:mb-6">
            Ready to Start Your Next Project?
          </h2>
          <p className="text-lg md:text-xl mb-8 md:mb-12 opacity-90 max-w-2xl mx-auto">
            Let's work together to bring your vision to life
          </p>
          <div className="space-x-4">
            <Link
              to="/contact"
              className="inline-block bg-gradient-affirm text-white font-nexa font-bold px-6 sm:px-8 py-3 sm:py-4 rounded-lg hover:opacity-90 transition-all transform hover:scale-105 text-sm sm:text-base"
            >
              Get in Touch
            </Link>
            <Link
              to="/about"
              className="inline-block bg-transparent border-2 border-white text-white font-nexa font-bold px-6 sm:px-8 py-3 sm:py-4 rounded-lg hover:bg-white hover:text-affirm-purple transition-all transform hover:scale-105 text-sm sm:text-base"
            >
              Learn More About Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
