import React from 'react';
import image from '../../assets/images/heroImages/AAHero1.jpg';
import design from '../../assets/images/heroImages/AAHero2.jpg';
import development from '../../assets/images/heroImages/AAHero3.jpg';
import testing from '../../assets/images/serviceImages/testing.jpg';

const WebDevelopment = () => {
  return (
    <div className="container mx-auto px-4 py-8 sm:py-12">
      <h1 className="text-3xl sm:text-4xl font-nexa font-bold text-gray-900 mb-6 sm:mb-8">Web Development</h1>
      
          <div className="mb-6 rounded-lg overflow-hidden shadow-lg">
            <img 
              src={image} 
              alt="Custom Web Solutions" 
              className="w-full h-auto"
            />
          </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-12">
        <div>
          <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-3 sm:mb-4">Custom Web Solutions</h2>
          <p className="text-sm sm:text-base text-gray-600 mb-6">
            We create custom, responsive websites that perfectly align with your business goals. 
            Our development process ensures your site is not only visually stunning but also 
            performs exceptionally well across all devices.
          </p>
          
          <h3 className="text-lg sm:text-xl font-nexa font-bold text-gray-800 mb-2 sm:mb-3">Our Expertise Includes:</h3>
          <ul className="list-disc list-inside text-sm sm:text-base text-gray-600 space-y-1.5 sm:space-y-2 mb-6">
            <li>Full-stack web development</li>
            <li>E-commerce solutions</li>
            <li>Content Management Systems (CMS)</li>
            <li>Progressive Web Apps (PWA)</li>
            <li>API development and integration</li>
            <li>Database design and optimisation</li>
          </ul>
        </div>
        
        <div>
          {/* <div className="mb-6 rounded-lg overflow-hidden shadow-lg">
            <img 
              src={image2} 
              alt="Technologies We Use" 
              className="w-full h-auto"
            />
          </div> */}
          <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-3 sm:mb-4">Technologies We Use</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="bg-white p-4 rounded-lg shadow">
              <h4 className="font-nexa font-bold text-gray-800 mb-2 text-base sm:text-lg">Frontend</h4>
              <ul className="text-sm sm:text-base text-gray-600 space-y-1">
                <li>React.js</li>
                <li>Vue.js</li>
                <li>Next.js</li>
                <li>TailwindCSS</li>
              </ul>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
              <h4 className="font-nexa font-bold text-gray-800 mb-2 text-base sm:text-lg">Backend</h4>
              <ul className="text-sm sm:text-base text-gray-600 space-y-1">
                <li>Node.js</li>
                <li>Python</li>
                <li>PHP</li>
                <li>Ruby on Rails</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mt-8 sm:mt-12">
        <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-4 sm:mb-6">Our Development Process</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src={design} 
              alt="Planning and Design" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">1. Planning & Design</h3>
              <p className="text-sm sm:text-base text-gray-600">
                We begin with thorough requirements gathering and create detailed wireframes 
                and prototypes to ensure your vision is perfectly captured.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src={development} 
              alt="Development" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">2. Development</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Our developers write clean, efficient code following best practices and 
                industry standards to ensure scalability and maintainability.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src={testing} 
              alt="Testing and Launch" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">3. Testing & Launch</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Rigorous testing ensures your website performs flawlessly before launch, 
                followed by continuous monitoring and support.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebDevelopment;
