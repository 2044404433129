import React from 'react';
import ServicesList from '../components/ServicesList';

const Services = () => {
  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16">
        <div className="text-center">
          <h1 className="text-3xl sm:text-4xl font-nexa font-bold text-affirm-purple mb-4">
            Our Services
          </h1>
          <div className="w-24 sm:w-32 h-1 bg-gradient-affirm mx-auto mb-8 sm:mb-12"></div>
        </div>
        
        <ServicesList />
      </div>
    </div>
  );
};

export default Services;
