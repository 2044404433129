import React from 'react';
import barber from '../../assets/images/serviceImages/barbers.jpg';

const HairBeauty = () => {
  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-16">
        <div className="mb-8 rounded-lg overflow-hidden shadow-lg">
          <img 
            src={barber}
            alt="Hair and Beauty Solutions" 
        className="w-full h-96 object-cover"
          />
        </div>

        <h1 className="text-3xl sm:text-4xl font-nexa font-bold text-gray-900 mb-4 sm:mb-8">
          Hair & Beauty Solutions
        </h1>
        
        <div className="prose max-w-none">
          <p className="text-base sm:text-lg text-gray-700 mb-6">
            We help hair and beauty businesses create stunning digital experiences that attract and retain clients. Our solutions are designed to showcase your work and streamline your booking process.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-8 my-8 sm:my-12">
            <div className="bg-gray-50 rounded-lg overflow-hidden">
              <img 
                src="https://placehold.co/600x300/2563eb/ffffff?text=Digital+Presence" 
                alt="Digital Presence" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4 sm:p-6">
                <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-900 mb-3 sm:mb-4">
                  Digital Presence
                </h2>
                <ul className="space-y-2 sm:space-y-3 text-sm sm:text-base">
                  <li>Beautiful, portfolio-focused websites</li>
                  <li>Online booking system integration</li>
                  <li>Before/after gallery showcases</li>
                  <li>Social media content strategy</li>
                </ul>
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg overflow-hidden">
              <img 
                src="https://placehold.co/600x300/2563eb/ffffff?text=Business+Growth" 
                alt="Business Growth" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4 sm:p-6">
                <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-900 mb-3 sm:mb-4">
                  Business Growth
                </h2>
                <ul className="space-y-2 sm:space-y-3 text-sm sm:text-base">
                  <li>Client management systems</li>
                  <li>Email marketing campaigns</li>
                  <li>Review management</li>
                  <li>Targeted local advertising</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 my-8 sm:my-12">
            <div className="bg-gray-50 rounded-lg overflow-hidden">
              <img 
                src="https://placehold.co/400x300/2563eb/ffffff?text=Online+Booking" 
                alt="Online Booking" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="font-nexa font-bold text-lg mb-2">Online Booking</h3>
                <p className="text-sm text-gray-700">Seamless appointment scheduling for your clients</p>
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg overflow-hidden">
              <img 
                src="https://placehold.co/400x300/2563eb/ffffff?text=Portfolio+Gallery" 
                alt="Portfolio Gallery" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="font-nexa font-bold text-lg mb-2">Portfolio Gallery</h3>
                <p className="text-sm text-gray-700">Showcase your best work to attract new clients</p>
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg overflow-hidden">
              <img 
                src="https://placehold.co/400x300/2563eb/ffffff?text=Client+Management" 
                alt="Client Management" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="font-nexa font-bold text-lg mb-2">Client Management</h3>
                <p className="text-sm text-gray-700">Efficient systems to manage your growing clientele</p>
              </div>
            </div>
          </div>

          <div className="bg-affirm-blue-light bg-opacity-10 rounded-lg overflow-hidden my-8 sm:my-12">
            <img 
              src="https://placehold.co/1200x400/2563eb/ffffff?text=Why+Choose+Us" 
              alt="Why Choose Us" 
              className="w-full h-64 object-cover"
            />
            <div className="p-4 sm:p-8">
              <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-900 mb-3 sm:mb-4">
                Why Choose Us?
              </h2>
              <p className="text-sm sm:text-base text-gray-700 mb-3 sm:mb-4">
                We understand that in the beauty industry, visual presentation is everything. Our team specialises in creating stunning digital experiences that reflect the quality and style of your services.
              </p>
              <p className="text-sm sm:text-base text-gray-700">
                Whether you're a boutique salon or a growing chain, we create solutions that help you showcase your work, manage your business efficiently, and grow your client base.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HairBeauty;
