import React from 'react';

const About = () => {
  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16">
        <div className="text-center mb-12 sm:mb-16">
          <h1 className="text-3xl sm:text-4xl font-nexa font-bold text-affirm-purple mb-4">
            About Affirm
          </h1>
          <div className="w-24 sm:w-32 h-1 bg-gradient-affirm mx-auto"></div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12 items-start lg:items-center">
          <div className="space-y-6">
            <h2 className="text-xl sm:text-2xl font-nexa font-bold text-affirm-purple">
              Transforming Ideas into Digital Reality
            </h2>
            <p className="text-gray-600 text-sm sm:text-base">
              At Affirm, we're passionate about creating digital solutions that make a difference. 
              Our team of expert developers and marketers work together to bring your vision to life.
            </p>
            <div className="space-y-6">
              <div className="flex items-center">
                <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-gradient-affirm flex items-center justify-center text-white">
                  <span className="text-base sm:text-xl">⚡</span>
                </div>
                <h3 className="ml-4 text-base sm:text-lg font-nexa font-bold text-affirm-purple">
                  Innovation First
                </h3>
              </div>
              <div className="flex items-center">
                <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-gradient-affirm flex items-center justify-center text-white">
                  <span className="text-base sm:text-xl">🎯</span>
                </div>
                <h3 className="ml-4 text-base sm:text-lg font-nexa font-bold text-affirm-purple">
                  Results Driven
                </h3>
              </div>
              <div className="flex items-center">
                <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-full bg-gradient-affirm flex items-center justify-center text-white">
                  <span className="text-base sm:text-xl">🤝</span>
                </div>
                <h3 className="ml-4 text-base sm:text-lg font-nexa font-bold text-affirm-purple">
                  Client Partnership
                </h3>
              </div>
            </div>
          </div>

          <div className="bg-gradient-affirm p-6 sm:p-8 rounded-lg text-white mt-8 lg:mt-0">
            <h3 className="text-xl sm:text-2xl font-nexa font-bold mb-6">Our Expertise</h3>
            <div className="space-y-6">
              <div className="flex items-start sm:items-center">
                <span className="text-xl sm:text-2xl mr-4">💻</span>
                <div>
                  <h4 className="font-nexa font-bold text-base sm:text-lg">Web Development</h4>
                  <p className="text-xs sm:text-sm opacity-90">Modern, responsive web applications</p>
                </div>
              </div>
              <div className="flex items-start sm:items-center">
                <span className="text-xl sm:text-2xl mr-4">📱</span>
                <div>
                  <h4 className="font-nexa font-bold text-base sm:text-lg">Mobile Development</h4>
                  <p className="text-xs sm:text-sm opacity-90">Native and cross-platform solutions</p>
                </div>
              </div>
              <div className="flex items-start sm:items-center">
                <span className="text-xl sm:text-2xl mr-4">📢</span>
                <div>
                  <h4 className="font-nexa font-bold text-base sm:text-lg">Digital Marketing</h4>
                  <p className="text-xs sm:text-sm opacity-90">Strategic social media presence</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
