import React from 'react';
import iconBlue from '../assets/images/iconBlue.svg';

const HoldingPage = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-white">
      <div className="text-center p-8">
        <img 
          src={iconBlue} 
          alt="Affirm Agency Logo" 
          className="w-32 h-32 mx-auto mb-8"
        />
        <h1 className="text-4xl font-bold mb-4 text-gray-800">
          Coming Soon
        </h1>
        <p className="text-xl text-gray-600 max-w-md mx-auto">
          We're currently working on something amazing. Stay tuned!
        </p>
      </div>
    </div>
  );
};

export default HoldingPage;
