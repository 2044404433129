import React, { useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import Home from './pages/Home'
import Services from './pages/Services'
import About from './pages/About'
import Contact from './pages/Contact'
import HoldingPage from './components/HoldingPage'
import WebDevelopment from './pages/services/WebDevelopment'
import DigitalMarketing from './pages/services/DigitalMarketing'
import BrandStrategy from './pages/services/BrandStrategy'
import UIUXDesign from './pages/services/UIUXDesign'
import MobileAppDevelopment from './pages/services/MobileAppDevelopment'
import Industries from './pages/Industries'
import CoffeeShops from './pages/industries/CoffeeShops'
import HairBeauty from './pages/industries/HairBeauty'
import Restaurants from './pages/industries/Restaurants'
import ScrollToTop from './components/ScrollToTop'

function App() {
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [password, setPassword] = useState('');

  const handleKeyPress = (e) => {
    if (e.key === 'l') {
      setShowPasswordField(prev => !prev);
      setPassword('');
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    if (value === 'letmein') {
      setMaintenanceMode(false);
      setShowPasswordField(false);
      setPassword('');
    }
  };

  React.useEffect(() => {
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  if (maintenanceMode) {
    return (
      <div style={{ position: 'relative' }}>
        {showPasswordField && (
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            style={{
              position: 'fixed',
              top: '10px',
              right: '10px',
              zIndex: 1000,
              padding: '5px',
              border: '1px solid #ccc',
              borderRadius: '4px'
            }}
            placeholder="Enter password"
            autoFocus
          />
        )}
        <HoldingPage />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="min-h-screen flex flex-col">
        <Navigation />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/services/web-development" element={<WebDevelopment />} />
            <Route path="/services/digital-marketing" element={<DigitalMarketing />} />
            <Route path="/services/brand-strategy" element={<BrandStrategy />} />
            <Route path="/services/uiux-design" element={<UIUXDesign />} />
            <Route path="/services/mobile-app-development" element={<MobileAppDevelopment />} />
            <Route path="/industries" element={<Industries />} />
            <Route path="/industries/coffee-shops" element={<CoffeeShops />} />
            <Route path="/industries/hair-beauty" element={<HairBeauty />} />
            <Route path="/industries/restaurants" element={<Restaurants />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  )
}

export default App
