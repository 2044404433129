import React from 'react';
import image from '../../assets/images/serviceImages/mobiledev.jpg';
import design from '../../assets/images/heroImages/AAHero2.jpg';
import development from '../../assets/images/heroImages/AAHero3.jpg';
import testing from '../../assets/images/serviceImages/testing.jpg';

const MobileAppDevelopment = () => {
  return (
    <div className="container mx-auto px-4 py-8 sm:py-12">
      <h1 className="text-3xl sm:text-4xl font-nexa font-bold text-gray-900 mb-6 sm:mb-8">Mobile App Development</h1>
      
          <div className="mb-6 rounded-lg overflow-hidden shadow-lg">
            <img 
          src={image} 
              alt="Native Mobile Apps" 
              className="w-full h-auto"
            />
          </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-12">
        <div>
          <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-3 sm:mb-4">Cross-Platform Mobile Solutions</h2>
          <p className="text-sm sm:text-base text-gray-600 mb-6">
            We develop high-performance mobile applications for both iOS and Android platforms using React Native. 
            Our apps deliver native-like performance while maintaining a single codebase, reducing development time 
            and costs while ensuring consistent user experience across platforms.
          </p>
          
          <h3 className="text-lg sm:text-xl font-nexa font-bold text-gray-800 mb-2 sm:mb-3">Key Features We Deliver:</h3>
          <ul className="list-disc list-inside text-sm sm:text-base text-gray-600 space-y-1.5 sm:space-y-2 mb-6">
            <li>Native UI components</li>
            <li>Offline functionality</li>
            <li>Push notifications</li>
            <li>Real-time data sync</li>
            <li>Device hardware integration</li>
            <li>Secure authentication</li>
          </ul>
        </div>
        
        <div>
          {/* <div className="mb-6 rounded-lg overflow-hidden shadow-lg">
            <img 
              src="https://placehold.co/600x400/2563eb/ffffff?text=React+Native+Tech" 
              alt="Technologies We Use" 
              className="w-full h-auto"
            />
          </div> */}
          <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-3 sm:mb-4">Technology Stack</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="bg-white p-4 rounded-lg shadow">
              <h4 className="font-nexa font-bold text-gray-800 mb-2 text-base sm:text-lg">Core Technologies</h4>
              <ul className="text-sm sm:text-base text-gray-600 space-y-1">
                <li>React Native</li>
                <li>TypeScript</li>
                <li>Redux/Context API</li>
                <li>Native Modules</li>
              </ul>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
              <h4 className="font-nexa font-bold text-gray-800 mb-2 text-base sm:text-lg">Development Tools</h4>
              <ul className="text-sm sm:text-base text-gray-600 space-y-1">
                <li>Expo</li>
                <li>Jest for Testing</li>
                <li>Firebase</li>
                <li>App Center</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      <div className="mt-8 sm:mt-12">
        <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-800 mb-4 sm:mb-6">Our App Development Process</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src={design} 
              alt="Discovery and Design" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">1. Discovery & Design</h3>
              <p className="text-sm sm:text-base text-gray-600">
                We start with understanding your requirements and creating detailed UI/UX designs 
                that follow platform-specific guidelines for iOS and Android.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src={development} 
              alt="Development" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">2. Development</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Our React Native experts build your app with clean, maintainable code, 
                ensuring optimal performance and smooth user experience on both platforms.
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <img 
              src={testing} 
              alt="Testing and Launch" 
              className="w-full h-48 object-cover"
            />
            <div className="p-4 sm:p-6">
              <h3 className="font-nexa font-bold text-gray-800 mb-2 sm:mb-3 text-base sm:text-lg">3. Testing & Launch</h3>
              <p className="text-sm sm:text-base text-gray-600">
                Comprehensive testing on real devices, followed by submission to the App Store 
                and Google Play Store with ongoing support and maintenance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileAppDevelopment;
