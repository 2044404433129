import React, { useState, useEffect } from 'react';
import AAHero1 from '../assets/images/heroImages/AAHero1.jpg';
import AAHero2 from '../assets/images/heroImages/AAHero2.jpg';
import AAHero3 from '../assets/images/heroImages/AAHero3.jpg';
import AAHero4 from '../assets/images/heroImages/AAHero4.jpg';
import AAHero5 from '../assets/images/heroImages/AAHero5.jpg';

const HeroBackground = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [AAHero1, AAHero2, AAHero3, AAHero4, AAHero5];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 6000); // Change image every 6 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="absolute inset-0 overflow-hidden">
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute inset-0 transition-opacity duration-1000 ease-in-out
            ${index === currentImageIndex ? 'opacity-100' : 'opacity-0'}`}
        >
          <div
            className="absolute inset-0 animate-kenBurns"
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '100%',
              width: '100%',
            }}
          />
        </div>
      ))}
      {/* Gradient overlay with increased opacity on mobile */}
      <div 
        className="absolute inset-0 bg-gradient-affirm md:opacity-75 opacity-85" 
        style={{
          background: `linear-gradient(
            to right,
            rgba(66, 209, 243, 0.85) 0%,
            rgba(54, 97, 251, 0.85) 100%
          )`
        }}
      />
    </div>
  );
};

export default HeroBackground;
