import React from 'react';
import coffee from '../../assets/images/serviceImages/coffee.jpg';

const CoffeeShops = () => {
  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-16">
        <div className="mb-8 rounded-lg overflow-hidden shadow-lg">
          <img 
            src={coffee}
            alt="Coffee Shop Solutions" 
            className="w-full h-96 object-cover"
          />
        </div>

        <h1 className="text-3xl sm:text-4xl font-nexa font-bold text-gray-900 mb-4 sm:mb-8">
          Coffee Shop Solutions
        </h1>
        
        <div className="prose max-w-none">
          <p className="text-base sm:text-lg text-gray-700 mb-6">
            We specialise in creating digital experiences that help coffee shops thrive in the modern market. Our solutions are crafted to enhance your brand presence and streamline operations.
          </p>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-8 my-8 sm:my-12">
            <div className="bg-gray-50 rounded-lg overflow-hidden">
              <img 
                src="https://placehold.co/600x300/2563eb/ffffff?text=Digital+Solutions" 
                alt="Digital Solutions" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4 sm:p-6">
                <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-900 mb-3 sm:mb-4">
                  Digital Solutions
                </h2>
                <ul className="space-y-2 sm:space-y-3 text-sm sm:text-base">
                  <li>Custom website design with online ordering capabilities</li>
                  <li>Mobile-responsive menu displays</li>
                  <li>Integration with popular delivery platforms</li>
                  <li>Social media management and content creation</li>
                </ul>
              </div>
            </div>

            <div className="bg-gray-50 rounded-lg overflow-hidden">
              <img 
                src="https://placehold.co/600x300/2563eb/ffffff?text=Brand+Development" 
                alt="Brand Development" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4 sm:p-6">
                <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-900 mb-3 sm:mb-4">
                  Brand Development
                </h2>
                <ul className="space-y-2 sm:space-y-3 text-sm sm:text-base">
                  <li>Brand identity and visual design</li>
                  <li>Menu design and photography</li>
                  <li>Local SEO optimisation</li>
                  <li>Customer loyalty programs</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 my-8 sm:my-12">
            <div className="bg-gray-50 rounded-lg overflow-hidden">
              <img 
                src="https://placehold.co/400x300/2563eb/ffffff?text=Online+Ordering" 
                alt="Online Ordering" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="font-nexa font-bold text-lg mb-2">Seamless Online Ordering</h3>
                <p className="text-sm text-gray-700">Custom-built ordering systems that integrate with your POS</p>
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg overflow-hidden">
              <img 
                src="https://placehold.co/400x300/2563eb/ffffff?text=Digital+Marketing" 
                alt="Digital Marketing" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="font-nexa font-bold text-lg mb-2">Digital Marketing</h3>
                <p className="text-sm text-gray-700">Targeted campaigns to reach coffee enthusiasts</p>
              </div>
            </div>
            <div className="bg-gray-50 rounded-lg overflow-hidden">
              <img 
                src="https://placehold.co/400x300/2563eb/ffffff?text=Loyalty+Programs" 
                alt="Loyalty Programs" 
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="font-nexa font-bold text-lg mb-2">Loyalty Programs</h3>
                <p className="text-sm text-gray-700">Digital rewards systems that keep customers coming back</p>
              </div>
            </div>
          </div>

          <div className="bg-affirm-blue-light bg-opacity-10 rounded-lg overflow-hidden my-8 sm:my-12">
            <img 
              src="https://placehold.co/1200x400/2563eb/ffffff?text=Why+Choose+Us" 
              alt="Why Choose Us" 
              className="w-full h-64 object-cover"
            />
            <div className="p-4 sm:p-8">
              <h2 className="text-xl sm:text-2xl font-nexa font-bold text-gray-900 mb-3 sm:mb-4">
                Why Choose Us?
              </h2>
              <p className="text-sm sm:text-base text-gray-700 mb-3 sm:mb-4">
                We understand the unique challenges and opportunities in the coffee shop industry. Our team has experience working with various coffee shops, helping them establish strong digital presence and grow their customer base.
              </p>
              <p className="text-sm sm:text-base text-gray-700">
                From artisanal coffee roasters to chain cafes, we create tailored solutions that reflect your unique brand and meet your specific business needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoffeeShops;
