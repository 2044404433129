import React from 'react';
import IndustriesList from '../components/IndustriesList';

const Industries = () => {
  return (
    <div className="min-h-screen bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-16">
        <h1 className="text-3xl sm:text-4xl font-nexa font-bold text-gray-900 mb-4 sm:mb-8">
          Industry Solutions
        </h1>
        
        <p className="text-base sm:text-lg text-gray-700 mb-8 sm:mb-12">
          We specialise in creating digital solutions for specific industries, understanding the unique challenges and opportunities each sector presents.
        </p>

        <IndustriesList />
      </div>
    </div>
  );
};

export default Industries;
